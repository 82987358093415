// App.js
// React / Chakra Imports
import { Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
// Contexts
import { LocationProvider } from 'contexts/LocationContext';
import { DateRangeProvider } from 'contexts/DateRangeContext';
// Layouts
import AdminLayout from './layouts/dashboard';
// Pages
import SignIn from 'views/auth/signinTwoColumns'
// Protected Route
import ProtectedRoute from 'ProtectedRoute';
// Theme
import initialTheme from './theme/theme';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme); 
  return (
    <DateRangeProvider>
      <LocationProvider>
          <ChakraProvider theme={currentTheme}>
            <Routes>
              <Route
                path="auth/signin/*"
                element={<SignIn />}
              />
              <Route
                path="dashboard/*"
                element={<ProtectedRoute><AdminLayout theme={currentTheme} setTheme={setCurrentTheme} /></ProtectedRoute>}
              />
              <Route
                path="/"
                element={<ProtectedRoute><Navigate to="/dashboard/overview" replace /></ProtectedRoute>} 
              />
            </Routes>
          </ChakraProvider>
      </LocationProvider>
    </DateRangeProvider>
  );
}