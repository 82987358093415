// StackedBarChart.js
// React Imports
import React from "react";
// recharts
import {
  BarChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
  Bar,
  LabelList
} from 'recharts';

class StackedBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      customTooltip: props.customTooltip,
      bars: props.bars,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.customTooltip !== this.props.customTooltip ||
      prevProps.bars !== this.props.bars
    ) {
      this.setState({
        chartData: this.props.chartData,
        customTooltip: this.props.customTooltip,
        bars: this.props.bars,
      });
    }
  }
  
  render() {
    console.log(this.state.bars)
    return (
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          layout="vertical"
          width={600}
          height={400}
          data={this.state.chartData}
          margin={{ right: 30, left: 20 }}
          stackOffset="expand"
          barSize={30}
        >
          <XAxis type="number" hide />
          <YAxis
            type="category" 
            dataKey="label" 
            axisLine={false} 
            tickLine={false} 
            tick={{ fill: 'white', fontSize: '14px' }}
          />
          <Tooltip content={this.state.customTooltip} cursor={{ fill: 'none' }} />
          <Legend wrapperStyle={{ fontSize: "12px", fontWeight: 'bold' }} />
          {this.state.bars.map((bar) => (
            <Bar dataKey={bar.dataKey} name={bar.name} stackId='a' fill={bar.fill}>
             <LabelList dataKey={bar.labelDataKey} position="inside" fill="white" style={{ fontSize: 13, fontWeight: 'bold' }} formatter={(value) => `${value}%`} />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default StackedBarChart;