// DateFilter.js
// React / Chakra Imports
import React from 'react';
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
// Date Imports
import { format } from 'date-fns';
// Icons
import { FaCalendarAlt } from 'react-icons/fa';
// Components
import MiniCalendar from "components/calendar/MiniCalendar";
// Contexts
import { useDateRange } from 'contexts/DateRangeContext';

export default function Banner() {
  const { dateRange, setDateRange } = useDateRange();

  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    onClose1(); // Close menu after selection
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      ml="10px"
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      <Menu isOpen={isOpen1} onClose={onClose1}>
        <MenuButton
          align='center'
          justifyContent='center'
          ml="20px"
          mr="20px"
          _hover={bgHover}
          w='auto'
          h='37px'
          lineHeight='100%'
          onClick={onOpen1}
          borderRadius='10px'
        >
          <Flex align='center' justifyContent="center">
            <Icon as={FaCalendarAlt} color={iconColor} w='14px' h='24px' />
            <Text ml='8px' fontSize='sm' color={textColor}>
              {format(dateRange[0], 'MMMM d, yyyy')} - {format(dateRange[1], 'MMMM d, yyyy')}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList
          w='300px'
          minW='unset'
          maxW='300px !important'
          border='transparent'
          backdropFilter='blur(63px)'
          bg={bgList}
          boxShadow={bgShadow}
          borderRadius='20px'
          p='15px'
        >
          <MiniCalendar
            selectRange={true}
            onChange={handleDateChange}
          />
        </MenuList>
      </Menu>
    </Flex>
  );
}
