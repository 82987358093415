// InsightsLineChartViz.js
// React / Chakra Imports
import {
  Flex,
  Box,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'; 
import React, { useMemo } from "react";
import { format, parseISO } from 'date-fns';
// Components
import LineChart from 'components/charts/LineChart';
import CardHeader from 'components/card/CardHeader';
import Card from 'components/card/Card';
// Variables
import {
  lineChartOptionsOverview,
} from 'variables/charts';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { filterColumns, aggregateDataByDate } from 'utils/dataFunctions';
import { useFilteredData } from 'utils/dataHooks';
import { formatNumberCharts } from "utils/dataFormatter";
// Icons
import { IoEllipse } from 'react-icons/io5';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function LineChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { pastPeriodData, currentPeriodData } = useFilteredData("overview", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const columnsToSum = useMemo(() => ['fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value'], []);
  const columnsToKeep = useMemo(() => ['total_purchases_value'], []);

  const aggregatedDataPast = useMemo(() => {
    const aggregatedData = aggregateDataByDate(pastPeriodData, 'day', columnsToSum, 'sum');
    return aggregatedData.map(item => ({
      ...item,
      total_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0) + (item.gg_purchases_value ?? 0),
    }));
  }, [pastPeriodData, columnsToSum]);

  const aggregatedDataCurrent = useMemo(() => {
    const aggregatedData = aggregateDataByDate(currentPeriodData, 'day', columnsToSum, 'sum');
    return aggregatedData.map(item => ({
      ...item,
      total_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0) + (item.gg_purchases_value ?? 0),
    }));
  }, [currentPeriodData, columnsToSum]);

  console.log("aggregatedDataPast:", aggregatedDataPast);
  console.log("aggregatedDataCurrent:", aggregatedDataCurrent);

  const currentPeriodDataByColumnsPast = useMemo(() => filterColumns(aggregatedDataPast, columnsToKeep), [aggregatedDataPast, columnsToKeep]);
  const currentPeriodDataByColumnsCurrent = useMemo(() => filterColumns(aggregatedDataCurrent, columnsToKeep), [aggregatedDataCurrent, columnsToKeep]);
  
  const seriesLineChartData = useMemo(() => {
    const pastData = currentPeriodDataByColumnsPast.length > 0 
      ? { name: "Past Period", data: currentPeriodDataByColumnsPast.map(d => d.total_purchases_value) }
      : { name: "Past Period", data: [] };
    const currentData = currentPeriodDataByColumnsCurrent.length > 0 
      ? { name: "Current Period", data: currentPeriodDataByColumnsCurrent.map(d => d.total_purchases_value) }
      : { name: "Current Period", data: [] };
    return [currentData, pastData];
  }, [currentPeriodDataByColumnsPast, currentPeriodDataByColumnsCurrent]);

  const currentPeriodDates = currentPeriodData && currentPeriodData.length > 0 
  ? currentPeriodData.map(d => format(parseISO(d.date), 'MM/dd/yy')) 
  : [];

  console.log("seriesLineChartData:", seriesLineChartData);

  // Calculate maxValue from finalData
  const maxValue = Math.max(
    ...seriesLineChartData.flatMap(({ data }) => data)
  );

  const newOptions = {
    ...lineChartOptionsOverview,
    yaxis: {
      show: true,
      min: 0,
      max: maxValue*1.2,
      tickAmount: 4, // Ensure 5 tick marks
      labels: {
          style: {
              colors: "#c8cfca",
              fontSize: "12px",
          },
          formatter: (val) => {
            return formatNumberCharts (val, 'dollar', true);
        }
      },
    },
    xaxis: {
      type: "date",
      categories: currentPeriodDates,
      labels: {
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '500',
          rotate: -45,
        },
        rotateAlways: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
            <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
								Total Purchases Value
							</Text>
							<Flex align='center' mt='5px'>
								<Icon as={IoEllipse} color='#2CD9FF' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									current period
								</Text>
								<Box mx={2} />
								<Text>{'  '}</Text>
								<Icon as={IoEllipse} color='#582CFF' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									past period
								</Text>
							</Flex>
            </MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
      >
      <Box minH="260px" mt="auto" mb="-20px" w="100%">
        <LineChart
          chartData={seriesLineChartData}
          chartOptions={newOptions}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
