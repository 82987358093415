// InsightsTableViz.js
// React / Chakra Imports
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
// Components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
import DateFormatDropdown from "components/filters/DateFormatDropdown";
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useDateFormat } from "contexts/DateFormatContext";
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByDate } from 'utils/dataFunctions';
import { formatNumber } from "utils/dataFormatter";
// Motions
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function TableViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { dateFormat } = useDateFormat();
  const { currentPeriodData } = useFilteredData("overview", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const columnsToKeep = useMemo(() => ['period', 'date', 'fb_spend', 'gg_spend', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value', 'tk_total_orders', 'tk_tickets', 'tk_ticket_gross_revenues'], []);
  const columnsToSum = useMemo(() => ['fb_spend', 'gg_spend', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value', 'tk_total_orders', 'tk_tickets', 'tk_ticket_gross_revenues'], []);

  const currentPeriodDataByColumns = useMemo(() => filterColumns(currentPeriodData, columnsToKeep), [currentPeriodData, columnsToKeep]);

  const finalData = useMemo(() => {
    const aggregatedData = aggregateDataByDate(currentPeriodDataByColumns, dateFormat, columnsToSum, 'sum');
  
    const parseDate = (date) => {
      switch (dateFormat) {
        case 'week':
          const startDate = date.split(' - ')[0].split(', ')[1];
          return new Date(startDate + ', ' + new Date().getFullYear());
        case 'month':
          return new Date(date.replace(',', ''));
        default:
          return new Date(date);
      }
    };
  
    const sortedData = aggregatedData.sort((a, b) => parseDate(b.date) - parseDate(a.date));

    return sortedData.map(item => ({
      ...item,
      total_spend: (item.fb_spend ?? 0) + (item.gg_spend ?? 0),
      total_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0) + (item.gg_purchases_value ?? 0),
      total_roas: (item.tk_ticket_gross_revenues ?? 0) / ((item.fb_spend ?? 0) + (item.gg_spend ?? 0)),
    }));
  }, [currentPeriodDataByColumns, dateFormat, columnsToSum]);

  const totals = useMemo(() => {
    const sum = (arr, accessor) => arr.reduce((acc, item) => acc + (item[accessor] ?? 0), 0);
  
    const total_fb_spend = sum(finalData, 'fb_spend');
    const total_gg_spend = sum(finalData, 'gg_spend');
    const total_total_spend = total_fb_spend + total_gg_spend;
    const total_total_purchases_value = sum(finalData, 'total_purchases_value');
    const total_tk_total_orders = sum(finalData, 'tk_total_orders');
    const total_tk_tickets = sum(finalData, 'tk_tickets');
    const total_tk_ticket_gross_revenues = sum(finalData, 'tk_ticket_gross_revenues');
    const total_total_roas = total_tk_ticket_gross_revenues / total_total_spend;
  
    return {
      date: 'Total',
      fb_spend: total_fb_spend,
      gg_spend: total_gg_spend,
      total_spend: total_total_spend,
      total_purchases_value: total_total_purchases_value,
      tk_total_orders: total_tk_total_orders,
      tk_tickets: total_tk_tickets,
      tk_ticket_gross_revenues: total_tk_ticket_gross_revenues,
      total_roas: total_total_roas,
    };
  }, [finalData]);
  
  const data = useMemo(() => finalData, [finalData]);
  const dataWithTotals = useMemo(() => [totals, ...finalData], [totals, finalData]);
  
  useEffect(() => {
    console.log("Date Format Changed:", dateFormat);
  }, [dateFormat]);

  const tableColumns = useMemo(() => [
    { Header: "Date", accessor: "date" },
    {
      Header: "META Spend",
      accessor: "fb_spend",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Google Spend",
      accessor: "gg_spend",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Total Spend",
      accessor: "total_spend",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Total Purchases Value",
      accessor: "total_purchases_value",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Orders",
      accessor: "tk_total_orders",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "Tickets",
      accessor: "tk_tickets",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "Ticket Gross Sales",
      accessor: "tk_ticket_gross_revenues",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Topline ROAS",
      accessor: "total_roas",
      Cell: ({ value }) => formatNumber(value, 'float')
    },
  ], []);

  const tableInstance = useTable({ columns: tableColumns, data: data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    
    <Card mb={{ base: "20px", xl: "0px" }}>
      <MotionFlex direction='column' w='100%' overflowX={{ sm: "scroll", lg: "hidden" }}  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Flex align='center' justify='space-between' w='100%' px='10px' mb='20px'>
          <CardHeader mt='10px' mb='10px' ps='22px'>
            <Flex direction='column' alignSelf='flex-start'>
              <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                Key Metrics
              </Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  by {dateFormat}
              </Text>
            </Flex>
          </CardHeader>
          <DateFormatDropdown />
        </Flex>
        <Flex align='center' justify='space-between' w='100%' px='10px' mb='20px' overflowX="scroll">
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    borderColor='transparent'
                    textAlign='center'
                    >
                    <Text
                      fontSize={{ sm: "10px", lg: "12px" }}
                      textAlign={index === 0 ? 'left' : 'center'}
                      color='gray.400'
                      w='100%'
                    >
                      {column.render("Header")}
                    </Text>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            <Tr>
              {tableColumns.map((column, index) => (
                <Td
                  key={index}
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  textAlign={['fb_spend', 'gg_spend', 'total_spend', 'total_purchases_value', 'tk_total_orders', 'tk_tickets', 'tk_ticket_gross_revenues', 'total_roas'].includes(column.accessor) ? 'center' : 'left'}
                  borderTop="1.5px solid"
                  borderBottom="1px solid"
                  borderTopColor="secondaryGray.500"
                  borderBottomColor="secondaryGray.800"
                  >
                  <Text
                    color={textColor}
                    fontSize='sm'
                    fontWeight='600'
                    w='100%'
                  >
                    {formatNumber(totals[column.accessor], column.accessor === 'total_roas' ? 'float' : column.accessor === 'tk_total_orders' || column.accessor === 'tk_tickets' ? 'integer' : 'dollar')}
                  </Text>
                </Td>
                
              ))}
            </Tr>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'
                      
                      mt='20px !important'
                      py='10px !important'
                      textAlign={['fb_spend', 'gg_spend', 'total_spend', 'total_purchases_value', 'tk_total_orders', 'tk_tickets', 'tk_ticket_gross_revenues', 'total_roas'].includes(cell.column.id) ? 'center' : 'left'}
                      >
                      <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='400'
                        w='100%'
                      >
                        {cell.render('Cell')}
                      </Text>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        </Flex>
      </MotionFlex>
    </Card>
  );
}
