// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import React, { useMemo } from "react";
// Custom components
import Card from 'components/card/Card';
// Data Functions
import { useData, useFilteredData } from 'utils/dataHooks';
import { filterColumns } from 'utils/dataFunctions';
import { formatNumber } from 'utils/dataFormatter';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';


// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

// ThisYearAttendees
export function ThisYearAttendees() {
  const data = useData("tickets_attendance_cards");

  console.log("data:", data)

  const columnsToKeep = ['total_tickets_sold_current_year'];

  const dataFiltered = filterColumns(data, columnsToKeep);

  const value = dataFiltered.length > 0 ? dataFiltered[0].total_tickets_sold_current_year : null;

  console.log("value:", value)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Utility to format date
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  // Get current date and format the date range
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const formattedDateRange = `${formatDate(startOfYear)} - ${formatDate(currentDate)}`;


    return (
      <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
    >
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            This Year's Attendees
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(value, 'integer')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="xs" mt="-10px" color={textColor}>
            {formattedDateRange}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// ThisMonthAttendees
export function ThisMonthAttendees() {
  const data = useData("tickets_attendance_cards");

  console.log("data:", data)

  const columnsToKeep = ['total_tickets_sold_current_month'];

  const dataFiltered = filterColumns(data, columnsToKeep);

  const value = dataFiltered.length > 0 ? dataFiltered[0].total_tickets_sold_current_month : null;

  console.log("value:", value)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Utility to format date
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  // Get current date and format the date range
  const currentDate = new Date();
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const formattedDateRange = `${formatDate(startOfMonth)} - ${formatDate(currentDate)}`;

    return (
      <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
    >
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            This Month's Attendees
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(value, 'integer')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="xs" mt="-10px" color={textColor}>
            {formattedDateRange}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// ThisWeekAttendees
export function ThisWeekAttendees() {
  const data = useData("tickets_attendance_cards");

  console.log("data:", data)

  const columnsToKeep = ['total_tickets_sold_current_week'];

  const dataFiltered = filterColumns(data, columnsToKeep);

  const value = dataFiltered.length > 0 ? dataFiltered[0].total_tickets_sold_current_week : null;

  console.log("value:", value)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Utility to format date
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  // Get current date and format the date range
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // Sunday - Saturday : 0 - 6

  // Calculate the start of the week (Monday)
  const startOfWeek = new Date(currentDate);
  const diff = currentDay === 0 ? 6 : currentDay - 1; // If today is Sunday (0), go back 6 days, otherwise subtract 1 day to get Monday
  startOfWeek.setDate(currentDate.getDate() - diff);

  const formattedDateRange = `${formatDate(startOfWeek)} - ${formatDate(currentDate)}`;

    return (
      <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
    >
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            This Week's Attendees
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(value, 'integer')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="xs" mt="-10px" color={textColor}>
            {formattedDateRange}
          </Text>
        </MotionFlex>
      </Card>
  );
};


// AverageDays
export function AverageDays() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_attendance_date_difference", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const filteredData = useMemo(() => 
    currentPeriodData
      .filter(item => item.date_difference !== -1)
      .sort((a, b) => {
        const aValue = a.date;
        const bValue = b.date;
        return aValue - bValue; 
      })
  , [currentPeriodData]);

  const totalAverage = useMemo(() => {
    if (filteredData.length === 0) return 0;

    const sum = filteredData.reduce((acc, item) => acc + item.date_difference, 0);
    return sum / filteredData.length;
  }, [filteredData]);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // Extract start and end dates from dateRange and format them
  const [startDate, endDate] = dateRange;
  const formattedDateRange = `${formatDate(startDate)} - ${formatDate(endDate)}`;


    return (
      <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
    >
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Average Days between purchase and event date
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(totalAverage, 'float-2')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="xs" mt="-10px" color={textColor}>
            Purchase Dates: {formattedDateRange}
          </Text>
        </MotionFlex>
      </Card>
  );
};
