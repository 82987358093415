// MetaTableViz.js
// React / Chakra Imports
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
// Components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
import DateFormatDropdown from "components/filters/DateFormatDropdown";
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useDateFormat } from "contexts/DateFormatContext";
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByDate } from 'utils/dataFunctions';
import { formatNumber } from "utils/dataFormatter";
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function TableViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { dateFormat } = useDateFormat();
  const { currentPeriodData } = useFilteredData("overview", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const columnsToKeep = useMemo(() => ['period', 'date', 'fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases_7d_click', 'fb_purchases_1d_view', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'], []);
  const columnsToSum = useMemo(() => ['fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases_7d_click', 'fb_purchases_1d_view', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'], []);

  const currentPeriodDataByColumns = useMemo(() => filterColumns(currentPeriodData, columnsToKeep), [currentPeriodData, columnsToKeep]);

  const finalData = useMemo(() => {
    const aggregatedData = aggregateDataByDate(currentPeriodDataByColumns, dateFormat, columnsToSum, 'sum');
  
    const parseDate = (date) => {
      switch (dateFormat) {
        case 'week':
          const startDate = date.split(' - ')[0].split(', ')[1];
          return new Date(startDate + ', ' + new Date().getFullYear());
        case 'month':
          return new Date(date.replace(',', ''));
        default:
          return new Date(date);
      }
    };
  
    const sortedData = aggregatedData.sort((a, b) => parseDate(b.date) - parseDate(a.date));

    return sortedData.map(item => ({
      ...item,
      fb_purchases: item.fb_purchases_7d_click && item.fb_purchases_1d_view ? (item.fb_purchases_7d_click + item.fb_purchases_1d_view) : 0,
      fb_purchases_value: item.fb_purchases_value_7d_click && item.fb_purchases_value_1d_view ? (item.fb_purchases_value_7d_click + item.fb_purchases_value_1d_view) : 0,
      fb_cpm: item.fb_impressions && item.fb_spend ? ((item.fb_spend / item.fb_impressions) * 1000) : 0,
      fb_ctr: item.fb_clicks && item.fb_impressions ? ((item.fb_clicks / item.fb_impressions) * 100) : 0,
      fb_cpc: item.fb_clicks && item.fb_spend ? (item.fb_spend / item.fb_clicks) : 0,
      fb_roas: item.fb_purchases_value_7d_click && item.fb_purchases_value_1d_view && item.fb_spend ? ((item.fb_purchases_value_7d_click + item.fb_purchases_value_1d_view) / item.fb_spend) : 0,
    }));
  }, [currentPeriodDataByColumns, dateFormat, columnsToSum]);

  const totals = useMemo(() => {
    const sum = (arr, accessor) => arr.reduce((acc, item) => acc + (item[accessor] ?? 0), 0);
  
    const total_fb_spend = sum(finalData, 'fb_spend');
    const total_fb_impressions = sum(finalData, 'fb_impressions');
    const total_fb_cpm = (total_fb_spend / total_fb_impressions) * 1000;
    const total_fb_clicks = sum(finalData, 'fb_clicks');
    const total_fb_ctr = (total_fb_clicks / total_fb_impressions) * 100;
    const total_fb_cpc = (total_fb_spend / total_fb_clicks);
    const total_fb_purchases = sum(finalData, 'fb_purchases');
    const total_fb_purchases_value = sum(finalData, 'fb_purchases_value');
    const total_fb_roas = (total_fb_purchases_value / total_fb_spend);
  
    return {
      date: 'Total',
      fb_spend: total_fb_spend,
      fb_impressions: total_fb_impressions,
      fb_cpm: total_fb_cpm,
      fb_clicks: total_fb_clicks,
      fb_ctr: total_fb_ctr,
      fb_cpc: total_fb_cpc,
      fb_purchases: total_fb_purchases,
      fb_purchases_value: total_fb_purchases_value,
      fb_roas: total_fb_roas,
    };
  }, [finalData]);
  
  const data = useMemo(() => finalData, [finalData]);
  const dataWithTotals = useMemo(() => [totals, ...finalData], [totals, finalData]);

  useEffect(() => {
    console.log("Date Format Changed:", dateFormat);
  }, [dateFormat]);

  const tableColumns = useMemo(() => [
    { Header: "Date", accessor: "date" },
    {
      Header: "Spend",
      accessor: "fb_spend",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Impressions",
      accessor: "fb_impressions",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "CPM",
      accessor: "fb_cpm",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Clicks",
      accessor: "fb_clicks",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "CTR",
      accessor: "fb_ctr",
      Cell: ({ value }) => formatNumber(value, 'percent-2')
    },
    {
      Header: "CPC",
      accessor: "fb_cpc",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Purchases",
      accessor: "fb_purchases",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "Purchases Value",
      accessor: "fb_purchases_value",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "ROAS",
      accessor: "fb_roas",
      Cell: ({ value }) => formatNumber(value, 'float')
    },
  ], []);

  const tableInstance = useTable({ columns: tableColumns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    
    <Card mb={{ base: "20px", xl: "0px" }}>
      <MotionFlex direction='column' w='100%' overflowX={{ sm: "scroll", lg: "hidden" }}  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Flex align='center' justify='space-between' w='100%' px='10px' mb='20px'>
          <CardHeader mt='10px' mb='10px' ps='22px'>
            <Flex direction='column' alignSelf='flex-start'>
              <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                Key Metrics
              </Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								by {dateFormat}
              </Text>
            </Flex>
          </CardHeader>
          <DateFormatDropdown />
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    borderColor='transparent'
                    textAlign='center'
                    >
                    <Text
                      fontSize={{ sm: "10px", lg: "12px" }}
                      textAlign={index === 0 ? 'left' : 'center'}
                      color='gray.400'
                      w='100%'
                    >
                      {column.render("Header")}
                    </Text>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            <Tr>
              {tableColumns.map((column, index) => (
                <Td
                  key={index}
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  textAlign={["fb_spend", "fb_impressions", "fb_cpm", "fb_clicks", "fb_ctr", "fb_cpc", "fb_purchases", "fb_purchases_value", "fb_roas"].includes(column.accessor) ? 'center' : 'left'}
                  borderTop="1.5px solid"
                  borderBottom="1px solid"
                  borderTopColor="secondaryGray.500"
                  borderBottomColor="secondaryGray.800"
                  >
                  <Text
                    color={textColor}
                    fontSize='sm'
                    fontWeight='600'
                    w='100%'
                  >
                    {formatNumber(
                      totals[column.accessor], column.accessor === 'fb_roas' ? 'float' : column.accessor === 'fb_ctr' ? 'percent-2' : column.accessor === 'fb_impressions' || column.accessor === 'fb_clicks' || column.accessor === 'fb_purchases'? 'integer' : 'dollar')}
                  </Text>
                </Td>
                
              ))}
            </Tr>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'
                      
                      mt='20px !important'
                      py='10px !important'
                      textAlign={["fb_spend", "fb_impressions", "fb_cpm", "fb_clicks", "fb_ctr", "fb_cpc", "fb_purchases", "fb_purchases_value", "fb_roas"].includes(cell.column.id) ? 'center' : 'left'}
                      >
                      <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='400'
                        w='100%'
                      >
                        {cell.render('Cell')}
                      </Text>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
  );
} 