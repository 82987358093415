// GoogleStackedBarChartViz.js
// React / Chakra Imports
import {
    Box,
    Flex,
    Text,
    useColorModeValue, 
  } from '@chakra-ui/react';
import React, { useMemo } from "react";
// Components
import StackedBarChart from 'components/charts/StackedBarChart';
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByColumnV2 } from 'utils/dataFunctions';
import { formatNumber } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

  export default function StackedBarChartViz() {
    const { location } = useLocation();
    const { dateRange } = useDateRange();
    const { currentPeriodData } = useFilteredData("google_campaigns", location, dateRange);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorChart = useColorModeValue('secondaryGray.500', 'white');

    const columnsToSumGoogle = useMemo(() => ['gg_spend', 'gg_impressions', 'gg_clicks', 'gg_purchases', 'gg_purchases_value'], []);

    const aggregatedDataGoogle = useMemo(() => aggregateDataByColumnV2(currentPeriodData, 'gg_campaign_type', columnsToSumGoogle, 'sum'), [currentPeriodData, columnsToSumGoogle]);

    console.log("aggregatedDataGoogle:", aggregatedDataGoogle);

    const columnsToKeepGoogle = useMemo(() => ['gg_campaign_type', 'gg_spend', 'gg_impressions', 'gg_clicks', 'gg_purchases', 'gg_purchases_value'], []);
    const currentPeriodDataByColumnsGoogle = useMemo(() => filterColumns(aggregatedDataGoogle, columnsToKeepGoogle), [aggregatedDataGoogle, columnsToKeepGoogle]);

    console.log("currentPeriodDataByColumnsGoogle:", currentPeriodDataByColumnsGoogle);

    const stackedBarChartData = useMemo(() => 
      currentPeriodDataByColumnsGoogle
        .filter(item => item.gg_campaign_type && item.gg_campaign_type !== "null")
        .map(item => ({
          name: item.gg_campaign_type.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, c => c.toUpperCase()), // Format the name
          data: [
            item.gg_spend,
            item.gg_impressions,
            item.gg_clicks,
            item.gg_purchases,
            item.gg_purchases_value,
          ]
        }))
        .reverse()
    , [currentPeriodDataByColumnsGoogle]);

    const getChartData = useMemo(() => (stackedBarChartData) => {
      const labels = ['Ad Spend', 'Impressions', 'Clicks', 'Purchases', 'Purchase Value'];
      const searchData = stackedBarChartData?.filter((each) => each.name === 'Search')[0];
      const performanceMaxData = stackedBarChartData?.filter((each) => each.name === 'Performance Max')[0];
      const summedValues = searchData?.data?.map((each, index) => each + performanceMaxData.data[index]);
      
      // Calculate percentages for search
      const searchPercentage = searchData?.data?.map((each, index) => Math.round((each / summedValues[index]) * 100));
      
      // Calculate percentages for performance max
      const performanceMaxPercentage = performanceMaxData?.data?.map((each, index) => Math.round((each / summedValues[index]) * 100));

      return labels.map((label, index) => {
        return {
          label,
          search: searchData?.data ? searchData?.data[index] : [],
          performanceMax: performanceMaxData?.data ? performanceMaxData?.data[index] : [],
          searchPercentage: searchPercentage ? searchPercentage[index] : [],
          performanceMaxPercentage: performanceMaxPercentage ? performanceMaxPercentage[index] : []
        }
      });
    }, []);

    const chartData = useMemo(() => getChartData(stackedBarChartData), [getChartData, stackedBarChartData]);

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
          <div className="custom-tooltip" style={{ backgroundColor: "rgba(28, 30, 37, 0.9)", color: "white", padding: "10px", borderRadius: "5px" }}>
            <b>{`${data.label}`}</b>
            <p style={{ color: "rgb(0, 143, 251)", fontSize: 14 }}>{`Search: ${formatNumber(Math.round(data.search))}`}</p>
            <p style={{ color: "rgb(0, 227, 150)", fontSize: 14 }}>{`Performance Max: ${formatNumber(Math.round(data.performanceMax))}`}</p>
          </div>
        );
      }
      return null;
    };

    const bars = [
      {
        dataKey: 'search',
        name: 'Search',
        labelDataKey: 'searchPercentage',
        fill: 'rgba(0, 143, 251, 0.85)'
      },
      {
        dataKey: 'performanceMax',
        name: 'Performance Max',
        labelDataKey: 'performanceMaxPercentage',
        fill: 'rgba(0, 227, 150, 0.85)'
      }
    ];

    return (
      <Card
        flexDirection="column"
        w="100%"
        // {...rest}
      >
        <CardHeader mt='10px' mb='10px' ps='22px'>
            <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                  Key Metrics
                </Text>
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  by campaign type
                </Text>
            </MotionFlex>
        </CardHeader>
        <MotionFlex
          direction="column"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
          w="100%"
        >
          <Box minH="260px" mt="auto" mb="0px" pr="15px" pl="15px" w="100%">
            <StackedBarChart
              chartData={chartData}
              customTooltip={<CustomTooltip />}
              bars={bars}
            />
          </Box>
        </MotionFlex>
      </Card>
    );
  }
  