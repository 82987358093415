// overview.jsx
// Chakra / React Imports
import React from "react";
import { Flex, Grid } from "@chakra-ui/react";
// Components
import { TotalSpend, TicketGrossSales, TicketNetSales, TotalTicketsSold, TotalOrders, AvgOrderValue } from "./components/OverviewHighLevelMetrics";
import OverviewLineChartViz from "./components/OverviewLineChartViz";
import OverviewColumnChartViz from "./components/OverviewColumnChartViz";
import OverviewTableViz from "./components/OverviewTableViz";
// Contexts
import { DateFormatProvider } from "contexts/DateFormatContext";

export default function Default() {

  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      width="100%" // Ensuring the container takes the ful/l widths
      mx="auto" // Centering the container horizontally
    >
      <Flex direction='column' width='stretch' >
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "repeat(6, 1fr)" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}
          width='100%'
        >
          <Flex gridArea={{ base: "auto", "2xl": "1 / 1 / 2 / 2" }}>
            <TotalSpend />
          </Flex>
          <Flex gridArea={{ base: "auto", "2xl": "1 / 2 / 2 / 3" }}>
            <TicketGrossSales />
          </Flex>
          <Flex gridArea={{ base: "auto", "2xl": "1 / 3 / 2 / 4" }}>
            <TicketNetSales />
          </Flex>
          <Flex gridArea={{ base: "auto", "2xl": "1 / 4 / 2 / 5" }}>
            <TotalTicketsSold />
          </Flex>
          <Flex gridArea={{ base: "auto", "2xl": "1 / 5 / 2 / 6" }}>
            <TotalOrders />
          </Flex>
          <Flex gridArea={{ base: "auto", "2xl": "1 / 6 / 2 / 7" }}>
            <AvgOrderValue />
          </Flex>
        </Grid>
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "720fr 350fr" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}
          width='100%'
        >
          <Flex gridArea={{ base: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
            <OverviewLineChartViz />
          </Flex>
          <Flex gridArea={{ base: "2 / 1 / 3 / 3", "2xl": "1 / 2 / 2 / 3" }} width='100%'>
            <OverviewColumnChartViz width='100%'/>
          </Flex>
        </Grid>
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "1fr" }}
          width='100%'
        >
          <Flex gridArea={{ base: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
          <DateFormatProvider>
            <OverviewTableViz />
            </DateFormatProvider>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
}