// charts.js
// Data Functions
import { formatNumberCharts } from "utils/dataFormatter";

export const lineChartDataOverview = [
  {
    name: "Current Period",
    data: [5000, 2500, 3000, 2200, 5000, 2500, 3000, 2300, 3000, 3500, 2500, 4000],
  },
  {
    name: "Past Period",
    data: [2000, 2300, 3000, 3500, 3700, 4200, 5500, 3500, 4000, 5000, 3300, 5500],
  },
];

// Find the maximum value from both series
const maxCurrentPeriod = Math.max(...lineChartDataOverview[0].data);
const maxPastPeriod = Math.max(...lineChartDataOverview[1].data);
const maxValue = Math.max(maxCurrentPeriod, maxPastPeriod);

// Calculate the new max value
const newMaxValue = Math.ceil((maxValue + (0.05 * maxValue)) / 1000) * 1000;

export const lineChartOptionsOverview = {
  chart: {
    fontFamily: 'Poppins',
    toolbar: {
      show: false,
    },
    selection: {
      enabled: false,
    },
    dropShadow: {
      enabled: false,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: '#4318FF',
    },
    events: {
      mouseMove: function(event, chartContext, config) {
        event.target.style.cursor = 'default';
      }
    }
  },
  colors: ['#00E5D1', '#566BDC'],
  markers: {
      size: [4, 4],
      colors: ['#00E5D1', '#566BDC'],
      strokeColors: ['#00E5D1', '#566BDC'],
      fillOpacity: [1, 1],
      shape: ['circle', 'circle'],
  },
  // markers: {
  //   size: 3,
  //   colors: ['#00E5D1', '#566BDC'], // Marker colors matching the line colors
  //   strokeColors: ['#00E5D1', '#566BDC'], // Stroke colors matching the line colors
  //   strokeWidth: 3,
  //   strokeOpacity: 0.9,
  //   strokeDashArray: 0,
  //   fillOpacity: 1,
  //   discrete: [],
  //   shape: 'circle',
  //   radius: 2,
  //   offsetX: 0,
  //   offsetY: 0,
  //   showNullDataPoints: true,
  // },
  tooltip: {
    theme: 'dark',
    y: {
      formatter: (val, { seriesIndex, dataPointIndex, w }) => {
        const currentValue = w.globals.series[0][dataPointIndex];
        const pastValue = w.globals.series[1][dataPointIndex];

        const percentageDifference = ((currentValue - pastValue) / pastValue) * 100;
        const color = percentageDifference >= 0 ? '#00E5D1' : '#FF8078';

        if (!val) {
          return '-';
        }

        if (seriesIndex === 0) {
          return `${formatNumberCharts (val, 'dollar', false)} <span style="color: ${color};">(${percentageDifference.toFixed(2)}%)</span>`;
        }
        return formatNumberCharts (val, 'dollar', false);
      }
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    type: 'line',
    dashArray: [0, 4]
  },
  yaxis: {
    show: true,
    min: 0, // Force y-axis to start at 0
    // max: 12000,
    tickAmount: 4, // Ensure 5 tick marks
    labels: {
        style: {
            colors: "#c8cfca",
            fontSize: "12px",
        },
        formatter: (val) => {
          return formatNumberCharts (val, 'dollar', true);
      }
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#404040",
    opacity: 0.1,
  },
};



export const columnChartDataOverview = [
  {
    name: 'Orders',
    data: [20, 30, 40, 20, 45, 50, 30],
  },
];

// Find the maximum value from both series
const maxOrders = Math.max(...columnChartDataOverview[0].data);

// Calculate the new max value
const newmaxOrders = maxOrders + 10

export const columnChartOptionsOverview = {
  chart: {
    fontFamily: 'Poppins',
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: '12px',
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
    theme: 'dark',
  },
  xaxis: {
    categories: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    show: false,
    min: 0, // Force y-axis to start at 0
    // max: newmaxOrders,
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: '#CBD5E0',
        fontSize: '14px',
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      type: 'vertical',
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: '#5158FF',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#5158FF',
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: '40px',
    },
  },
};
