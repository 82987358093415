// GoogleHighLevelMetrics.js
// Chakra Imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
// Components
import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, useAggregatedData} from 'utils/dataFunctions';
import { formatNumber, calculateChange, renderChange } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

// Google Spend
export function GoogleSpend() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'gg_spend'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_spend', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_spend', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google Spend
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Purchases Value
export function GooglePurchasesValue() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'gg_purchases_value'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_purchases_value', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_purchases_value', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google Purchases Value
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Google ROAS
export function GoogleROAS() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeepNum = ['period', 'date', 'gg_purchases_value'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'gg_purchases_value', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'gg_purchases_value', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'gg_spend'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'gg_spend', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'gg_spend', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google ROAS
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'float')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Impressions
export function GoogleImpressions() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'gg_impressions'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_impressions', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_impressions', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google Impressions
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Clicks
export function GoogleClicks() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'gg_clicks'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_clicks', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_clicks', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google Clicks
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google CTR
export function GoogleCTR() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeepNum = ['period', 'date', 'gg_clicks'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'gg_clicks', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'gg_clicks', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'gg_impressions'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'gg_impressions', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'gg_impressions', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = (currentPeriodValueNum / currentPeriodValueDen) * 100
  const pastPeriodValue = (pastPeriodValueNum / pastPeriodValueDen) * 100

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google CTR
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'percent-2')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google CPC
export function GoogleCPC() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeepNum = ['period', 'date', 'gg_spend'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'gg_spend', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'gg_spend', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'gg_clicks'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'gg_clicks', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'gg_clicks', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Google CPC
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};