// OverviewolumnChartViz.js
// React / Chakra Imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import React, { useMemo } from "react";
// Components
import BarChart from 'components/charts/BarChart';
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Variables
import {
  columnChartOptionsOverview,
} from 'variables/charts';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { aggregateDataByDate } from 'utils/dataFunctions';
import { formatNumber } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_attendance_date_difference", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');

  const columnsToSum = useMemo(() => ['date_difference'], []);

  const filteredData = useMemo(() => 
    currentPeriodData
      .filter(item => item.date_difference !== -1)
      .sort((a, b) => {
        const aValue = a.date;
        const bValue = b.date;
        return aValue - bValue; 
      })
  , [currentPeriodData]);

  console.log("filteredData:", filteredData);

  const aggregatedData = useMemo(() => aggregateDataByDate(filteredData, 'day_of_week', columnsToSum, 'average'), [filteredData, columnsToSum]);

  console.log("aggregatedData:", aggregatedData);

  const columnChartData = useMemo(() => 
    [
    {
      name: 'Average Days',
      data: aggregatedData.map(item => (item.date_difference ?? 0) ),
    },
  ]
  , [aggregatedData]);

  console.log("columnChartData:", columnChartData);

  const allValues = columnChartData.flatMap(day => day.data);
  const maxValue = Math.max(...allValues);
  const maxValueChart = Math.ceil(maxValue);
  console.log("allValues:", allValues);
  console.log("maxValue:", maxValue);
  console.log("maxValueChart:", maxValueChart);

  const columnChartCategories = useMemo(() => 
    aggregatedData
    .map(item => item.date)
    , [aggregatedData]);

  console.log("columnChartCategories:", columnChartCategories);


const newOptions = {
  ...columnChartOptionsOverview,
  plotOptions: {
    ...columnChartOptionsOverview.plotOptions,
    bar: {
      horizontal: true,
      borderRadius: 5,
      dataLabels: {
        position: 'top', // This will position the data labels at the end of the bars
        hideOverflowingLabels: true,
      },
    },
  },
  dataLabels: {
    enabled: true,
    // textAnchor: 'end',
    formatter: function (val) {
      return formatNumber(val, 'float')
    },
    style: {
      colors: [textColorChart],
      fontSize: '14px',
    },
    offsetX: 90,
  },
  xaxis: {
    categories: columnChartCategories,
    show: false,
    min: 0, // Force y-axis to start at 0
    max: maxValueChart * 1.1,
    labels: {
      show: false,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    ...columnChartOptionsOverview.yaxis,
    show: true,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: textColorChart,
        fontSize: '14px',
      },
    },
  },
  tooltip: {
    theme: "dark",
    y: {
      formatter: (val) => formatNumber(val, 'float', false),
    },
  },
  fill: {
    colors: ['#00E5D1'],
  },
};

  return (
    <Card
      flexDirection="column"
      w="100%"
      // height="100vh"
      height="100%"
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
						<MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
								Average Days
							</Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								from purchase to event date
              </Text>
						</MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
      >
      <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%">
        <BarChart
          chartData={columnChartData}
          chartOptions={newOptions}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
