// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';
import React, { useMemo, useEffect } from "react";

// Custom components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
import {
  columnChartDataOverview,
  columnChartOptionsOverview,
} from 'variables/charts';

import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByDate, aggregateDataByColumnV2} from 'utils/dataFunctions';

// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_insights_barchart_dow", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');

  console.log("currentPeriodData:", currentPeriodData);

  // // Step 1: Filter the currentPeriodData based on the desired criteria
  // const filteredCurrentPeriodData = useMemo(() => 
  //   currentPeriodData.filter(item => item.ticket_status === "Live")
  // , [currentPeriodData]);

  // console.log("filteredCurrentPeriodData:", filteredCurrentPeriodData);

  // Step 2: Define the columns to sum
  const columnsToSum = useMemo(() => ['total_tickets'], []);

  // Step 3: Aggregate the filtered data by the specified column
  const aggregatedData = useMemo(() => aggregateDataByDate(currentPeriodData, 'day_of_week', columnsToSum, 'sum'), [currentPeriodData, columnsToSum]);

  console.log("aggregatedData:", aggregatedData);

  const columnChartData = useMemo(() => 
    [
    {
      name: 'Tickets Sold',
      data: aggregatedData.map(item => (item.total_tickets ?? 0) ),
    },
  ]
  , [aggregatedData]);

  console.log("columnChartData:", columnChartData);

const columnChartCategories = useMemo(() => 
  aggregatedData
  .map(item => item.date)
  , [aggregatedData]);

  console.log("columnChartCategories:", columnChartCategories);

  const newOptions = {
    ...columnChartOptionsOverview,
    plotOptions: {
      ...columnChartOptionsOverview.plotOptions,
      bar: {
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          position: 'top', // This will position the data labels at the end of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [textColorChart],
        fontSize: '13px',
      },
      offsetX: 30,
    },
    xaxis: {
      categories: columnChartCategories,
      show: false,
      min: 0, // Force y-axis to start at 0
      // max: newmaxOrders,
      labels: {
        show: false,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      ...columnChartOptionsOverview.yaxis,
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: textColorChart,
          fontSize: '14px',
        },
      },
    },
  fill: {
    colors: ['#00E5D1'], // Set the color of the bars to white
  },
};

  // Chakra Color Mode
  // const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card
      flexDirection="column"
      w="100%"
      // {...rest}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
        <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
            Tickets Sold
          </Text>
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            by day of the week
          </Text>
        </MotionFlex>
      </CardHeader>
      <MotionFlex direction='column' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%">
          <BarChart
            chartData={columnChartData}
            chartOptions={newOptions}
          />
        </Box>
      </MotionFlex>
    </Card>
  );
}
