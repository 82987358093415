// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';
import React, { useMemo, useEffect } from "react";

// Custom components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
import {
  columnChartDataOverview,
  columnChartOptionsOverview,
} from 'variables/charts';

import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';

import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, sumColumns, aggregateDataByColumnV2} from 'utils/dataFunctions';
import { formatNumber } from 'utils/dataFormatter';

import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_insights_barchart_ticket", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');


  const columnsToSum = useMemo(() => ['total_tickets'], []);

  const aggregatedData = useMemo(() => aggregateDataByColumnV2(currentPeriodData, 'price_level_names', columnsToSum, 'sum'), [currentPeriodData, columnsToSum]);
  const totalTicketsSum = useMemo(() => aggregatedData.reduce((sum, item) => sum + item.total_tickets, 0), [aggregatedData]);

  const topAggregatedData = useMemo(() => 
    aggregatedData
      .sort((a, b) => b.total_tickets - a.total_tickets)
      .slice(0, 5)
      .map(item => ({
        ...item,
        percentage: ((item.total_tickets / totalTicketsSum) * 100).toFixed(1)
      }))
  , [aggregatedData]);

  const columnChartData = useMemo(() => 
    [
    {
      name: 'Tickets Sold',
      // data: topAggregatedData.map(item => item.total_tickets),
      data: topAggregatedData.map(item => ({
        x: item.price_level_names,
        y: item.total_tickets,
        percentage: item.percentage
      })),
    },
  ]
  , [topAggregatedData]);

const columnChartCategories = useMemo(() => 
  topAggregatedData
  .map(item => item.price_level_names)
  , [topAggregatedData]);

  const newOptions = {
    ...columnChartOptionsOverview,
    plotOptions: {
      ...columnChartOptionsOverview.plotOptions,
      bar: {
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          // position: 'top', // This will position the data labels at the end of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [textColorChart],
        fontSize: '13px',
      },
      offsetX: 30,
      formatter: function (val, opts) {
        const percentage = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].percentage;
        return `${formatNumber(val, 'integer', false)} (${percentage}%)`;
      },
    },
    xaxis: {
      categories: columnChartCategories,
      show: false,
      min: 0, // Force y-axis to start at 0
      // max: newmaxOrders,
      labels: {
        show: false,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      ...columnChartOptionsOverview.yaxis,
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: textColorChart,
          fontSize: '14px',
        },
      },
    },
    fill: {
      colors: ['#2577F6'], // Set the color of the bars to white
    },
  };

  // Chakra Color Mode
  // const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card
      flexDirection="column"
      w="100%"
      // {...rest}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
						<MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
								Tickets Sold
							</Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								by ticket type
              </Text>
						</MotionFlex>
      </CardHeader>
      <MotionFlex direction='column' width="95%" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Box minH="260px" mt="auto" mb="0px" pl="25px" w="100%">
          <BarChart
            chartData={columnChartData}
            chartOptions={newOptions}
          />
        </Box>
      </MotionFlex>
    </Card>
  );
}
