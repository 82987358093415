// DateRangeContext.js
// React Imports
import React, { createContext, useState, useContext, useEffect } from 'react';
// Date Imports
import { differenceInDays, subDays } from 'date-fns';

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = subDays(today, 1);
  const last7Days = [subDays(yesterday, 6), yesterday];
  const [dateRange, setDateRange] = useState(last7Days);
  const [pastDateRange, setPastDateRange] = useState(last7Days);
  const [currentMode, setCurrentMode] = useState('days');

  useEffect(() => {
    const currentStart = new Date(dateRange[0]);
    currentStart.setHours(0, 0, 0, 0);
    const currentEnd = new Date(dateRange[1]);
    currentEnd.setHours(0, 0, 0, 0);
    if (currentMode === 'days') {
      const rangeDays = differenceInDays(currentEnd, currentStart) + 1;
      const previousStart = new Date(currentStart);
      previousStart.setDate(previousStart.getDate() - rangeDays);
      const previousEnd = new Date(currentEnd);
      previousEnd.setDate(previousEnd.getDate() - rangeDays);

      setPastDateRange([previousStart, previousEnd]);
    } else if (currentMode === 'week') {
      const rangeDays = differenceInDays(currentEnd, currentStart);
      const previousStart = new Date(currentStart);
      if (rangeDays > 7) {
        const difference = Math.ceil(rangeDays / 7) * 7;
        previousStart.setDate(currentStart.getDate() - difference);
      } else {
        previousStart.setDate(currentStart.getDate() - 7);
      }
      const previousEnd = new Date(previousStart);
      previousEnd.setDate(previousStart.getDate() + rangeDays);

      setPastDateRange([previousStart, previousEnd]);
    }
  }, [currentMode, dateRange]);

  console.log("yesterday:", yesterday);
  console.log("last7Days:", last7Days);
  console.log("pastDateRange:", pastDateRange);
  console.log("dateRange:", dateRange);

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange, pastDateRange, currentMode, setCurrentMode }}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRange = () => useContext(DateRangeContext);