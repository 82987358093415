// AuthContext.js
// React Imports
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { refreshToken } from 'utils/AuthUtils';
import { signIn } from 'utils/AuthUtils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('authenticated') === 'true' || localStorage.getItem('authenticated') === 'true');
  const [cognitoUser, setCognitoUser] = useState(null);
  const [name, setName] = useState(null);
  const [company, setCompany] = useState(null);
  const [keepSignedInState, setKeepSignedInState] = useState(localStorage.getItem('keepSignIn') === 'true');
  const navigate = useNavigate();

  useEffect(() => {
    const authState = sessionStorage.getItem('authenticated') === 'true' || localStorage.getItem('authenticated') === 'true';

    if (authState) {
      setIsAuthenticated(true);
      setCompany(sessionStorage.getItem('company') || localStorage.getItem('company'));
      setName(sessionStorage.getItem('name') || localStorage.getItem('name'));
    } else {
      setIsAuthenticated(false);
      navigate('/auth/signin');
    }
  }, [navigate]);

  useEffect(() => {
    const storeSessionData = (newAccessToken, userAttributes) => {
      if (keepSignedInState) {
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('company', userAttributes.profile);
        localStorage.setItem('name', userAttributes.name);
      } else {
        sessionStorage.setItem('accessToken', newAccessToken);
        sessionStorage.setItem('authenticated', 'true');
        sessionStorage.setItem('company', userAttributes.profile);
        sessionStorage.setItem('name', userAttributes.name);
      }
    };
  
    const refreshTokenAndStore = () => {
      return refreshToken()
        .then(({ accessToken: newAccessToken, user, userAttributes }) => {
          console.log('Token refreshed:', newAccessToken);
          setCognitoUser(user);
          storeSessionData(newAccessToken, userAttributes);
        })
        .catch(err => {
          console.error('Token refresh failed:', err);
          logout();
        });
    };
  
    refreshTokenAndStore();
  
    const interval = setInterval(() => {
      const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
      if (accessToken) {
        console.log('Checking access token expiration');
        if (isTokenExpired(accessToken)) {
          refreshTokenAndStore();
        }
      }
    }, 60000); // Check every minute
  
    return () => clearInterval(interval);
  }, []);

  const login = async (email, password, keepSignedIn) => {
    try {
      const { accessToken, refreshToken, userAttributes, user } = await signIn(email, password);
      if (keepSignedIn) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('company', userAttributes.profile);
        localStorage.setItem('name', userAttributes.name);
        localStorage.setItem('keepSignedIn', 'true');
      }
      else {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('authenticated', 'true');
        sessionStorage.setItem('company', userAttributes.profile);
        sessionStorage.setItem('name', userAttributes.name);
      }
      document.cookie = `refreshToken=${refreshToken}; HttpOnly; Secure; SameSite=Strict`;
      setIsAuthenticated(true);
      setCognitoUser(user);
      setName(userAttributes.name);
      setCompany(userAttributes.profile);
      setKeepSignedInState(keepSignedIn);
      return true; // Indicate successful login
    } catch (error) {
      console.error('Login error: ', error);
      return false; // Indicate failed login
    }
  };

  const logout = () => {
    if (cognitoUser) {
      sessionStorage.clear();
      localStorage.clear();
      cognitoUser.signOut();
      setCognitoUser(null);
      setIsAuthenticated(false);
      setName(null);
      setCompany(null);
      setKeepSignedInState(false);
      navigate('/auth/signin');
    }
  };

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }
  
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
  
    // Check if token will expire in the next minute (60000 ms)
    return expirationTime - currentTime < 60000;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, company, name, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);