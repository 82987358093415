// MiniCalendar.js
// React / Chakra Imports
import React, { useState } from 'react';
import { useColorModeValue, Icon, Text } from '@chakra-ui/react';
// Components
import Calendar from "react-calendar";
import Card from "components/card/Card.js";
// Icons
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// CSS
import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";

export default function MiniCalendar(props) {
  const { onChange, selectRange, ...rest } = props;
  const [value, setValue] = useState([new Date(), new Date()]);

  const handleDateChange = (date) => {
    setValue(date);
    if (selectRange && date.length === 2) {
      onChange(date); // Call the parent's onChange function to update the date range
    }
  };

  const borderColor = useColorModeValue("transparent", "whiteAlpha.100");

  return (
    <Card
      border='1px solid'
      borderColor={borderColor}
      align='center'
      direction='column'
      w='100%'
      maxW='max-content'
      p='20px 15px'
      h='max-content'
      {...rest}>
      <Calendar
        onChange={handleDateChange}
        value={value}
        selectRange={selectRange}
        returnValue="range"
        view={"month"}
        tileContent={<Text color='brand.500'></Text>}
        prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
        nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
      />
    </Card>
  );
}
