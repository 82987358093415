// React Imports
import { useState, useEffect } from 'react';
// Auth Context Import
import { useAuth } from 'AuthContext';
// Date Imports
import { isWithinInterval, parseISO } from 'date-fns';
// Date Range Context
import { useDateRange } from 'contexts/DateRangeContext';

const cache = {};

export const useFilteredData = (view, selectedLocation, selectedDateRange) => {
  const { pastDateRange } = useDateRange();
  const { company } = useAuth(); // Get the company from AuthContext
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPeriodData, setCurrentPeriodData] = useState([]);
  const [pastPeriodData, setPastPeriodData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (cache[view]) {
        console.log("Using cached data for view:", view);
        const cachedData = cache[view];
        setData(cachedData);
        applyFilter(cachedData, selectedLocation, selectedDateRange, pastDateRange);
      } else {
        try {
          const server_port = 'https://xeuscloud.com';
          let url = `${server_port}/data/${view}`;
          console.log("Fetching data from:", url);
          const response = await fetch(url, {
            headers: {
              'Company': company, // Add company to request headers
            },
          });
          const jsonData = await response.json();
          console.log("Fetched data:", jsonData);

          let parsedData;
          if (view === 'tickets_events') {
            parsedData = jsonData.map(d => ({
              ...d,
              date: d.order_completed_date ? parseISO(d.order_completed_date) : null, // Ensure dates are parsed correctly
            }));
          } else {
            parsedData = jsonData.map(d => ({
              ...d,
              date: d.date ? parseISO(d.date) : null, // Ensure dates are parsed correctly
            }));
          }

          cache[view] = parsedData; // Cache the data
          setData(parsedData);
          applyFilter(parsedData, selectedLocation, selectedDateRange, pastDateRange);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };

    fetchData();
  }, [view, selectedLocation, selectedDateRange, pastDateRange, company]); // Add company as a dependency

  useEffect(() => {
    console.log("Applying filter with location:", selectedLocation);
    console.log("Applying filter with date range:", selectedDateRange);
    applyFilter(data, selectedLocation, selectedDateRange, pastDateRange);
  }, [data, selectedLocation, selectedDateRange, pastDateRange]);

  const applyFilter = (data, selectedLocation, selectedDateRange, pastDateRange) => {
    const [currentStart, currentEnd] = selectedDateRange;
    const [previousStart, previousEnd] = pastDateRange;

    const isWithinSelectedRange = d => isWithinInterval(d.date, { start: currentStart, end: currentEnd });
    const isWithinPreviousRange = d => isWithinInterval(d.date, { start: previousStart, end: previousEnd });

    const currentData = [];
    const pastData = [];

    data.forEach(d => {
      if (isWithinSelectedRange(d) && (selectedLocation ? d.location === selectedLocation : true)) {
        currentData.push({ ...d, date: d.date.toISOString(), period: 'Current Period' });
      } else if (isWithinPreviousRange(d) && (selectedLocation ? d.location === selectedLocation : true)) {
        pastData.push({ ...d, date: d.date.toISOString(), period: 'Past Period' });
      }
    });

    const filtered = [...currentData, ...pastData];

    setFilteredData(filtered);
    setCurrentPeriodData(currentData);
    setPastPeriodData(pastData);
  };

  return {
    data,
    filteredData,
    currentPeriodData,
    pastPeriodData,
  };
};

export const useData = (view) => {
  const { company } = useAuth(); // Get the company from AuthContext
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (cache[view]) {
        console.log("Using cached data for view:", view);
        const cachedData = cache[view];
        setData(cachedData);
      } else {
        try {
          const server_port = 'https://xeuscloud.com';
          let url = `${server_port}/data/${view}`;
          console.log("Fetching data from:", url);
          const response = await fetch(url, {
            headers: {
              'Company': company, // Add company to request headers
            },
          });
          const jsonData = await response.json();
          console.log("Fetched data:", jsonData);

          let parsedData;
          if (jsonData.length > 0 && 'order_completed_date' in jsonData[0]) {
            parsedData = jsonData.map(d => ({
              ...d,
              date: d.order_completed_date ? parseISO(d.order_completed_date) : null, // Ensure dates are parsed correctly
            }));
          } else if (jsonData.length > 0 && 'date' in jsonData[0]) {
            parsedData = jsonData.map(d => ({
              ...d,
              date: d.date ? parseISO(d.date) : null, // Ensure dates are parsed correctly
            }));
          } else {
            parsedData = jsonData; // Data does not contain date fields
          }

          cache[view] = parsedData; // Cache the data
          setData(parsedData);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };

    fetchData();
  }, [view, company]); // Add company as a dependency

  return data;
};

export const useDataV2 = (view, selectedLocation, selectedDateRange) => {
  const { company } = useAuth(); // Get the company from AuthContext
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (cache[view]) {
        console.log("Using cached data for view:", view);
        const cachedData = cache[view];
        setData(applyLocationFilter(cachedData, selectedLocation, selectedDateRange));
      } else {
        try {
          const server_port = 'https://xeuscloud.com';
          let url = `${server_port}/data/${view}`;
          console.log("Fetching data from:", url);
          const response = await fetch(url, {
            headers: {
              'Company': company, // Add company to request headers
            },
          });
          const jsonData = await response.json();
          console.log("Fetched data:", jsonData);

          let parsedData;
          if (jsonData.length > 0 && 'order_completed_date' in jsonData[0]) {
            parsedData = jsonData.map(d => ({
              ...d,
              date: d.order_completed_date ? parseISO(d.order_completed_date) : null, // Ensure dates are parsed correctly
            }));
          } else if (jsonData.length > 0 && 'date' in jsonData[0]) {
            parsedData = jsonData.map(d => ({
              ...d,
              date: d.date ? parseISO(d.date) : null, // Ensure dates are parsed correctly
            }));
          } else {
            parsedData = jsonData; // Data does not contain date fields
          }

          cache[view] = parsedData; // Cache the data
          setData(applyLocationFilter(parsedData, selectedLocation, selectedDateRange));
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };

    fetchData();
  }, [view, selectedLocation, selectedDateRange, company]); // Add company as a dependency

  const applyLocationFilter = (data, selectedLocation, selectedDateRange) => {
    if (!selectedLocation) {
      return [];
    }

    const filteredData = data.filter(d => d.location === selectedLocation);

    if (!selectedDateRange) {
      return filteredData;
    }

    const [currentStart, currentEnd] = selectedDateRange;
    return filteredData.filter(d => d.date && isWithinInterval(d.date, { start: currentStart, end: currentEnd }));
  };

  return data;
};


export const useLocationFilteredData = (view, selectedLocation) => {
  const { company } = useAuth(); // Get the company from AuthContext
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (cache[view]) {
        console.log("Using cached data for view:", view);
        const cachedData = cache[view];
        setData(applyLocationFilter(cachedData, selectedLocation));
      } else {
        try {
          const server_port = 'https://xeuscloud.com';
          let url = `${server_port}/data/${view}`;
          console.log("Fetching data from:", url);
          const response = await fetch(url, {
            headers: {
              'Company': company, // Add company to request headers
            },
          });
          const jsonData = await response.json();
          console.log("Fetched data:", jsonData);

          cache[view] = jsonData; // Cache the data
          setData(applyLocationFilter(jsonData, selectedLocation));
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };

    fetchData();
  }, [view, selectedLocation, company]); // Add company as a dependency

  const applyLocationFilter = (data, selectedLocation) => {
    if (!selectedLocation) {
      return [];
    }

    return data.filter(d => d.location === selectedLocation);
  };

  return data;
};