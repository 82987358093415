// AuthUtils.js
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID
});

const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                const accessToken = result.getAccessToken().getJwtToken();
                const refreshToken = result.getRefreshToken().getToken();

                user.getUserAttributes((err, attributes) => {
                    if (err) {
                      console.error('Error retrieving user attributes:', err);
                      reject(err);
                    } else {
                      const userAttributes = {};
                      attributes.forEach((attribute) => {
                        userAttributes[attribute.getName()] = attribute.getValue();
                      });
                      resolve({ accessToken, refreshToken, user, userAttributes });
                    }
                });
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                // Handle the new password required state here
                console.log('New password required:', userAttributes, requiredAttributes);
        
                // Here, you should prompt the user to enter a new password.
                // For this example, we'll just use a placeholder for the new password.
                const newPassword = prompt("Enter your new password:");
        
                user.completeNewPasswordChallenge(newPassword, {}, {
                  onSuccess: (result) => {
                    console.log('Password changed successfully:', result);

                    const accessToken = result.getAccessToken().getJwtToken();
                    const refreshToken = result.getRefreshToken().getToken();

                    user.getUserAttributes((err, attributes) => {
                        if (err) {
                        console.error('Error retrieving user attributes:', err);
                        reject(err);
                        } else {
                        const userAttributes = {};
                        attributes.forEach((attribute) => {
                            userAttributes[attribute.getName()] = attribute.getValue();
                        });
                        resolve({ accessToken, refreshToken, user, userAttributes });
                        }
                    });
                  },
                  onFailure: (err) => {
                    console.error('Error changing password:', err);
                    reject(err);
                  },
                });
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

const refreshToken = () => {
    return new Promise((resolve, reject) => {
        const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
    
        if (!refreshToken) {
            return reject(new Error('No refresh token found'));
        }
    
        const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
    
        const user = userPool.getCurrentUser();
    
        if (!user) {
            return reject(new Error('No user found'));
        }
    
        user.refreshSession(cognitoRefreshToken, (err, session) => {
            if (err) {
                return reject(err);
            }
    
            const accessToken = session.getAccessToken().getJwtToken();

            user.getUserAttributes((err, attributes) => {
                if (err) {
                  console.error('Error retrieving user attributes:', err);
                  reject(err);
                } else {
                  const userAttributes = {};
                  attributes.forEach((attribute) => {
                    userAttributes[attribute.getName()] = attribute.getValue();
                  });
                  resolve({ accessToken, refreshToken, user, userAttributes });
                }
            });
        });
    });
};

export {
    signIn,
    refreshToken
}
