// AttendanceCalendarViz.js
// React / Chakra Imports
import React, { useState } from 'react';
import { useColorModeValue, Icon, Text, Box, Flex } from '@chakra-ui/react';
import Calendar from "react-calendar";
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks';
// Icons
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// CSS
import "assets/css/Calendar.css"; // Create this CSS file to add custom styles
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

const ordersData = {
  "2024-07-01": 5,
  "2024-07-02": 10,
  "2024-07-03": 2,
  "2024-07-04": 15,
  "2024-07-05": 20,
  "2024-07-06": 12,
  "2024-07-07": 5,
  "2024-07-08": 10,
  "2024-07-09": 2,
  "2024-07-10": 15,
  "2024-07-11": 20,
  "2024-07-12": 12,
  "2024-07-13": 1,
  "2024-07-14": 15,
  "2024-07-15": 20,
  "2024-07-16": 12,
};

export default function AttendanceCalendar() {
  const today = new Date();
  const sixMonthsBefore = new Date(today.getFullYear(), today.getMonth() - 6, 1);
  const sixMonthsAfter = new Date(today.getFullYear(), today.getMonth() + 6, 0);
  const formattedDatesArray = [sixMonthsBefore, sixMonthsAfter].map(date => date.toString());

  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_insights_treemap", location, formattedDatesArray);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [value, setValue] = useState(new Date());

  const calendarData = currentPeriodData.reduce((acc, curr) => {
    const date = curr.date.split('T')[0]; // Extract date part only (YYYY-MM-DD)
    acc[date] = (acc[date] || 0) + curr.total_tickets;
    return acc;
  }, {});

  const handleDateChange = (date) => {
    setValue(date);
  };

  const borderColor = useColorModeValue("transparent", "whiteAlpha.100");

  // const getBackgroundColor = (orders) => {
  //   const maxOrders = 10; // Adjust this based on your data's maximum value
  //   const intensity = Math.min(orders / maxOrders, 1);
  //   const blueIntensity = Math.floor(intensity * 255);
  //   return `rgba(0, 0, ${blueIntensity}, 0.2)`; // Adjust alpha for transparency
  // };

  // const customColors = ['#023E8A', '#0077B6', '#0096C7', '#00B4D8', '#48CAE4', '#90E0EF', '#ADE8F4', '#CAF0F8'];

  const customColors = [
    '#02387C',
    '#023B83',
    '#0F4890',
    '#1B5196',
    '#285B9C',
    '#3565A1',
    '#416EA7',
    '#4E78AD',
    '#5B82B3',
    '#678BB9',
    '#7495BF',
    '#819FC5',
    '#8DA8CA',
    '#9AB2D0',
    '#A6BBD6',
    '#B3C5DC',
    '#C0CFE2',
    '#CCD8E8',
    '#D9E2ED',
    '#E6ECF3',
    ].reverse();

  const getBackgroundColor = (orders) => {
    const maxOrders = Math.max(...Object.values(calendarData));
    const index = Math.min(Math.floor((orders / maxOrders) * (customColors.length - 1)), customColors.length - 1);
    return customColors[index];
  };

  const renderTileContent = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];
      const orders = calendarData[dateString];
      const backgroundColor = orders ? getBackgroundColor(orders) : 'transparent';

      return (
        <Box className="tile-content" style={{ backgroundColor }}>
          {/* <Text className="tile-date">{date.getDate()}</Text> */}
          {orders ? <Text className="tile-orders" color='brand.500'>{orders}</Text> : null}
        </Box>
      );
    }
  };

  return (
    // <Card
    //   border='1px solid'
    //   borderColor={borderColor}
    //   align='center'
    //   maxH='100%'
    //   >
    <Card
      flexDirection="column"
      w="100%"
      // height="100vh"
      height="100%"
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
        <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
            Attendance
          </Text>
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            by month
          </Text>
        </MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        display="flex"
        align='center'
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
        // h="100%"
        // height="100vh"
        flexGrow={1} // Ensure it grows to take the available height
      >
        <Calendar
          onChange={handleDateChange}
          value={value}
          minDate={sixMonthsBefore}
          maxDate={sixMonthsAfter}
          // selectRange={selectRange}
          returnValue="range"
          view={"month"}
          direction="column"
          display="flex"
          tileContent={renderTileContent}
          prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
          nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
          className="custom-calendar" // Add this class for custom styling
        />
      </MotionFlex>
    </Card>
  );
}