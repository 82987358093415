// InsightsStackedBarChartViz.js
// React / Chakra Imports
import {
    Box,
    Flex,
    Text,
    useColorModeValue, 
  } from '@chakra-ui/react';
import StackedBarChart from 'components/charts/StackedBarChart';
import React, { useMemo } from "react";
// Components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByColumnV2, extractAndFormatDataStackedChart} from 'utils/dataFunctions';
import { formatNumber } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

  export default function StackedBarChartViz() {
    const { location } = useLocation();
    const { dateRange } = useDateRange();
    const { currentPeriodData } = useFilteredData("overview", location, dateRange);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorChart = useColorModeValue('secondaryGray.500', 'white');

    const columnsToSumMETA = useMemo(() => ['fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases_7d_click', 'fb_purchases_1d_view', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'], []);

    const aggregatedDataMETA = useMemo(() => {
      const aggregatedData = aggregateDataByColumnV2(currentPeriodData, 'period', columnsToSumMETA, 'sum');
      return aggregatedData.map(item => ({
        ...item,
        fb_purchases: (item.fb_purchases_7d_click ?? 0) + (item.fb_purchases_1d_view ?? 0),
        fb_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0),
      }));
    }, [currentPeriodData, columnsToSumMETA]);

    const labels = useMemo(() => ['Ad Spend', 'Impressions', 'Clicks', 'Purchases', 'Purchase Value'], []);
    const columnsToKeepMETA = useMemo(() => ['fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases', 'fb_purchases_value'], []);
    const currentPeriodDataByColumnsMETA = useMemo(() => filterColumns(aggregatedDataMETA, columnsToKeepMETA), [aggregatedDataMETA, columnsToKeepMETA]);
    
    const columnsToSumGoogle = useMemo(() => ['gg_spend', 'gg_impressions', 'gg_clicks', 'gg_purchases', 'gg_purchases_value'], []);

    const aggregatedDataGoogle = useMemo(() => {
      const aggregatedData = aggregateDataByColumnV2(currentPeriodData, 'period', columnsToSumGoogle, 'sum');
      return aggregatedData;
    }, [currentPeriodData, columnsToSumGoogle]);

    const columnsToKeepGoogle = useMemo(() => ['gg_spend', 'gg_impressions', 'gg_clicks', 'gg_purchases', 'gg_purchases_value'], []);
    const currentPeriodDataByColumnsGoogle = useMemo(() => filterColumns(aggregatedDataGoogle, columnsToKeepGoogle), [aggregatedDataGoogle, columnsToKeepGoogle]);

    const stackedBarChartData = useMemo(() => {
      const metaData = currentPeriodDataByColumnsMETA.length > 0 
        ? extractAndFormatDataStackedChart(currentPeriodDataByColumnsMETA, columnsToKeepMETA, labels) 
        : [];
      const googleData = currentPeriodDataByColumnsGoogle.length > 0 
        ? extractAndFormatDataStackedChart(currentPeriodDataByColumnsGoogle, columnsToKeepGoogle, labels) 
        : [];
      return { metaData, googleData };
    }, [currentPeriodDataByColumnsMETA, currentPeriodDataByColumnsGoogle, columnsToKeepMETA, columnsToKeepGoogle, labels]);

    const getChartData = useMemo(() => (stackedBarChartData) => {
      // Combine data
      const { metaData, googleData } = stackedBarChartData;
      const combinedData = [...metaData, ...googleData];
      
      // Calculate total value
      const sumByLabel = (combinedData) => {
        return combinedData.reduce((acc, { label, y }) => {
          if (!acc[label]) {
            acc[label] = 0;
          }
          acc[label] += y;
          return acc;
        }, {});
      };
      
        // Get summed values
      const summedValues = sumByLabel(combinedData);
    
      // Calculate percentages for metaData
      const metaDataPercentages = metaData.map((point) => Math.round((point.y / summedValues[point.label]) * 100));
      
      // Calculate percentages for googleData
      const googleDataPercentages = googleData.map((point) => Math.round((point.y / summedValues[point.label]) * 100));
    
      return labels.map((label, index) => {
        return {
          label,
          meta: metaData ? metaData[index]?.y : [],
          google: googleData ? googleData[index]?.y : [],
          metaPercentage: metaDataPercentages ? metaDataPercentages[index] : [],
          googlePercentage: googleDataPercentages ? googleDataPercentages[index] : []
        }
      });
    }, [labels]);

    const chartData = useMemo(() => getChartData(stackedBarChartData), [getChartData, stackedBarChartData]);

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
          <div className="custom-tooltip" style={{ backgroundColor: "rgba(28, 30, 37, 0.9)", color: "white", padding: "10px", borderRadius: "5px" }}>
            <b>{`${data.label}`}</b>
            <p style={{ color: "rgb(0, 143, 251)", fontSize: 14 }}>{`Search: ${formatNumber(Math.round(data.search))}`}</p>
            <p style={{ color: "rgb(0, 227, 150)", fontSize: 14 }}>{`Performance Max: ${formatNumber(Math.round(data.performanceMax))}`}</p>
          </div>
        );
      }
      return null;
    };

    const bars = [
      {
        dataKey: 'meta',
        name: 'Meta',
        labelDataKey: 'metaPercentage',
        fill: 'rgba(0, 143, 251, 0.85)'
      },
      {
        dataKey: 'google',
        name: 'Google',
        labelDataKey: 'googlePercentage',
        fill: 'rgba(0, 227, 150, 0.85)'
      }
    ];

    return (
      <Card
        flexDirection="column"
        w="100%"
        // {...rest}
      >
        <CardHeader mt='10px' mb='10px' ps='22px'>
          <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
              <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                Key Metrics
              </Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  by ad platform
              </Text>
          </MotionFlex>
        </CardHeader>
        <MotionFlex
          direction="column"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
          w="100%"
        >
        <Box minH="260px" mt="auto" mb="0px" pr="15px" pl="15px" w="100%">
          <StackedBarChart
            chartData={chartData}
            customTooltip={<CustomTooltip />}
            bars={bars}
          />
        </Box>
        </MotionFlex>
      </Card>
    );
  }
  