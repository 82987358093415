// dataFormatter.js

// formatNumber
export const formatNumber = (value, type) => {
    const addCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

    if (typeof value !== 'number') {
        console.error(`Expected a number but got ${typeof value}:`, value);
        return value;
    }

    switch (type) {
        case 'float':
        return addCommas(value.toFixed(1));
        case 'float-2':
        return addCommas(value.toFixed(2));
        case 'integer':
        return addCommas(Math.round(value));
        case 'dollar':
        return `$${addCommas(value.toFixed(2))}`;
        case 'percent':
        return `${value.toFixed(1)}%`;
        case 'percent-2':
        return `${value.toFixed(2)}%`;
        default:
        return addCommas(value);
    }
};

// formatNumberCharts
export const formatNumberCharts = (val, type, division = true) => {
    const addCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    if (val === 0) {
        return "";
    }

    val = Number(val);

    switch (type) {
        case 'integer':
            if (division) {
                if (val >= 1000000) {
                    return `${(val / 1000000).toFixed(1)}M`;
                }
                if (val >= 1000) {
                    return `${(val / 1000).toFixed(1)}K`;
                }
            }
            return addCommas(Math.round(val));
        case 'dollar':
            if (division) {
                if (val >= 1000000) {
                    return `$${(val / 1000000).toFixed(1)}M`;
                }
                if (val >= 1000) {
                    return `$${(val / 1000).toFixed(1)}K`;
                }
            }
            return `$${addCommas(val.toFixed(1))}`;
        case 'percent':
            return `${val.toFixed(1)}%`;
        default:
            return addCommas(val);
    }
};

// calculateChange
export const calculateChange = (current, previous) => {
    return previous ? ((current - previous) / previous * 100).toFixed(1) : 'N/A';
    };

// renderChange
export const renderChange = (change, type = 'percent') => {
    if (change === 'N/A') return <span>{change}</span>;
    const changeValue = Math.abs(parseFloat(change));
    const isPositive = parseFloat(change) >= 0;
    const triangle = isPositive ? '▲' : '▼';
    const color = isPositive ? '#15AEA5' : '#DB4444';
    return (
        <span style={{ color }}>
        {triangle} {formatNumber(changeValue, type)}
        </span>
    );
    };