// MetricsMetaDropdown.js
// React / Chakra Imports
import React from 'react';
import { Flex, Icon, Select, useColorModeValue } from '@chakra-ui/react';
// Icons
import { MdOutlineCalendarToday } from 'react-icons/md';
// Contexts
import { useMetricsMeta } from 'contexts/MetricsMetaContext';

const MetricsMetaDropdown = () => {
  const { metrics, setMetricsMeta } = useMetricsMeta();
  const handleChange = (event) => {
    setMetricsMeta(event.target.value);
  };


  const bg = useColorModeValue('background.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Flex alignItems="center" bg={bg} p={2} borderRadius="md">
      <Icon as={MdOutlineCalendarToday} color={textColor} mr={2} />
      <Select value={metrics} onChange={handleChange} size="sm" variant="outline">
        <option value="ctr">CTR</option>
        <option value="cpm">CPM</option>
        <option value="cpc">CPC</option>
        <option value="roas">ROAS</option>
      </Select>
    </Flex>
  );
};

export default MetricsMetaDropdown;

