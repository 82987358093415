// OverviewLineChartViz.js
import { css } from '@emotion/react';
// React / Chakra Imports
import {
  Flex,
  Box,
  Icon,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
// Components
import LineChart from 'components/charts/LineChart';
import CardHeader from 'components/card/CardHeader';
import Card from 'components/card/Card';
// Variables
import {
  lineChartOptionsOverview,
} from 'variables/charts';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { filterColumns, transformDataToJSON } from 'utils/dataFunctions';
import { useFilteredData } from 'utils/dataHooks';
import { formatNumberCharts } from "utils/dataFormatter";
// Icons
import { IoEllipse } from 'react-icons/io5';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function LineChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { filteredData, currentPeriodData } = useFilteredData("overview", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  console.log("Location:", location);
  console.log("Date Range:", dateRange);
  console.log("Filtered Data:", filteredData);
  console.log("Current Period Data:", currentPeriodData);

  const columnsToKeep = ['period', 'date', 'tk_ticket_gross_revenues'];

  const filteredDataByColumns = filterColumns(filteredData, columnsToKeep);

  const transformedData = transformDataToJSON(filteredDataByColumns, columnsToKeep);

  console.log('Transformed Data:', transformedData);

  const currentPeriodDates = currentPeriodData && currentPeriodData.length > 0 
  ? currentPeriodData.map(d => format(parseISO(d.date), 'MM/dd/yy')) 
  : [];
  
  const finalData = transformedData.map(({ name, data }) => ({ name, data }));

  console.log('Final Data:', finalData);
  console.log('Current Dates:', currentPeriodDates);

  // Calculate maxValue from finalData
  const maxValue = Math.max(
    ...finalData.flatMap(({ data }) => data)
  );

  const newOptions = {
    ...lineChartOptionsOverview,
    chart: {
      ...lineChartOptionsOverview.chart,
      selection: {
        enabled: false,
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: (val) => {
            return formatNumberCharts (val, 'dollar', false);
        }
      },
    },
    // tooltip: {
    //   theme: 'dark',
    //   custom: ({ series, seriesIndex, dataPointIndex, w }) => {
    //     const currentValue = series[0][dataPointIndex];
    //     const previousValue = series[1][dataPointIndex];
    //     const percentageDifference = previousValue
    //       ? ((currentValue - previousValue) / previousValue) * 100
    //       : 0;
    //       const currentDate = w.globals.labels[dataPointIndex]
    //       const previousDate = w.globals.labels

    //       console.log(currentDate)
  
    //     // Inline SVG for the Chakra UI IoEllipse icon
    //     const currentIconSVG = `
    //       <svg stroke="currentColor" fill="#00E5D1" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M256 48C141.125 48 48 141.125 48 256s93.125 208 208 208 208-93.125 208-208S370.875 48 256 48z"></path>
    //       </svg>`;
    //     const previousIconSVG = `
    //       <svg stroke="currentColor" fill="#566BDC" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M256 48C141.125 48 48 141.125 48 256s93.125 208 208 208 208-93.125 208-208S370.875 48 256 48z"></path>
    //       </svg>`;
  
    //     return (
    //       `<div>
    //         <div><strong>${currentDate} - ${previousDate}</strong></div>
    //         <div style="display: flex; align-items: center;">
    //           ${previousIconSVG} 
    //           <span style="margin-left: 5px;">Past Period: ${formatNumberCharts(previousValue, 'dollar', true)}</span>
    //         </div>
    //         <div style="display: flex; align-items: center;">
    //           ${currentIconSVG} 
    //           <span style="margin-left: 5px;">Current Period: ${formatNumberCharts(currentValue, 'dollar', true)} (${percentageDifference.toFixed(2)}%)</span>
    //         </div>
    //       </div>`
    //     );
    //   },
    // },
    yaxis: {
      show: true,
      min: 0,
      max: maxValue*1.2,
      tickAmount: 4, // Ensure 5 tick marks
      labels: {
          style: {
              colors: "#c8cfca",
              fontSize: "12px",
          },
          formatter: (val) => {
            return formatNumberCharts (val, 'dollar', true);
        }
      },
    },
    xaxis: {
      type: "date",
      categories: currentPeriodDates,
      labels: {
        style: {
          colors: '#A3AED0',
          fontSize: '13px',
          fontWeight: '500',
          rotate: -45,
        },
        rotateAlways: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    }
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
						<MotionFlex
              direction='column'
                alignSelf='flex-start'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                css={css`
                  cursor: default;
                  &:hover {
                    cursor: default;
                  }
                `}  
              >
							<Text fontSize='lg' v fontWeight='bold' mb='0px'>
								Ticket Gross Sales
							</Text>
							<Flex align='center' mt='5px'>
								<Icon as={IoEllipse} color='#00E5D1' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									current period
								</Text>
								<Box mx={2} />
								<Text>{'  '}</Text>
								<Icon as={IoEllipse} color='#566BDC' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									past period
								</Text>
							</Flex>
						</MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
        css={css`
          cursor: default;
          &:hover {
            cursor: default;
          }
        `}
      >
      <Box
        minH="260px"
        mt="auto"
        mb="-20px"
        w="100%"
        >
        <LineChart
          chartData={finalData}
          chartOptions={newOptions}
          css={css`
            cursor: default;
            &:hover {
              cursor: default;
            }
          `}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
