// DateRangeContext.js
// React Imports
import React, { createContext, useState, useContext } from 'react';
// Date Imports
import { addDays } from 'date-fns';

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
  const today = new Date();
  const last7Days = [addDays(today, -7), today];
  const [dateRange, setDateRange] = useState(last7Days);

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRange = () => useContext(DateRangeContext);